<template>
	<div>

		<div v-if="pageBusy" class="ui inverted active dimmer" style="z-index: 999;">
			<div class="content">
				<h2 class="ui small icon header" style="color: black;">
					<i class="small spinner loading icon"></i>
				</h2>
			</div>
		</div>

		<div class="ui center aligned header header-title">Université Mohammed V de Rabat</div>

		<div class="ui text container" style="position:relative">
			<svg class="absolute left-full transform translate-x-1/2 faded dots" width="404" height="404" fill="none"
			     viewBox="0 0 404 404">
				<defs>
					<pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0" width="20" height="20"
					         patternUnits="userSpaceOnUse">
						<rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor"/>
					</pattern>
				</defs>
				<rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"/>
			</svg>
			<svg class="absolute right-full bottom-0 transform -translate-x-1/2 faded dots" width="404" height="404"
			     fill="none"
			     viewBox="0 0 404 404">
				<defs>
					<pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0" width="20" height="20"
					         patternUnits="userSpaceOnUse">
						<rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor"/>
					</pattern>
				</defs>
				<rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"/>
			</svg>
			<div class="ui center aligned header header-title-2">
				Service des documents référentiels<br>
			</div>

			<h1 class="ui top attached header padded">
				<div>
					<h3 class="section-title">Données étudiant</h3>
				</div>
			</h1>
			<div class="ui attached segment px-0">
				<table class="ui very basic very padded striped table">
					<tbody>
					<tr>
						<td class="line-label">Nom et Prénom</td>
						<td class="line-info">{{attachment.etudiant.lib_nom_pat_ind}} {{attachment.etudiant.lib_pr1_ind}}</td>
					</tr>
					<tr>
						<td class="line-label">CIN</td>
						<td class="line-info">{{attachment.etudiant.cin_ind}}</td>
					</tr>
					<tr>
						<td class="line-label">CNE/MASSAR</td>
						<td class="line-info">{{attachment.etudiant.cod_etu}}</td>
					</tr>
					<tr>
						<td class="line-label">Type Document</td>
						<td class="line-info">{{attachment.label}}</td>
					</tr>

					</tbody>
				</table>
			</div>


				<h3 class="ui attached header section-title">
					Documents
				</h3>
				<div class="ui attached segment">
					<form class="ui form" id="form">
						<div class="field">
							<label> Fichier </label>
							<div class="upload-file" @click.prevent="downloadFile($store.state.SERVER_ADDRESS  + attachment.signed_version.link)">
								<div class="upload-content">
									<i class="file pdf icon red huge"></i>
									<h3> {{attachment.file_name}} </h3>
								</div>
							</div>
						</div>
						<div class="action-buttons" style="text-align: right;">
							<button class="ui right labeled icon upload-button button " @click.prevent="downloadFile($store.state.SERVER_ADDRESS  + attachment.signed_version.link)">
								Télécharger
								<i class="right download icon"></i>
							</button>
						</div>
					</form>
				</div>

		</div>
	</div>

</template>

<script>
	export default {
		name: "dossier",
		props:{"attachment":Object},
		data() {
			return {
				pageBusy: false,
				isBusy: false,
				there_are_files: false,
				selected_file: null
			}
		},
		methods: {
			downloadFile(file_path) {
				//let file_path = this.$store.state.SERVER_ADDRESS + '/' + this.data.file.path;
				let a = document.createElement('a');
				a.href = file_path;
				a.setAttribute('download', file_path);
				a.setAttribute('target', '_blanc');
				a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				console.log(file_path);
			},
		},
		mounted(){
			// if no attachment comming from prop go back to home page
			if (!this.attachment) {
				this.$router.push({name: 'home'});
			}
		}
	}
</script>

<style scoped>
	#file {
		opacity: 0;
		width: 100%;
		height: 100%;
		position: absolute;
	}

	.upload-file {
		border: 2px dashed #051a42;
		position: relative;
		cursor: pointer;
	}

	.upload-content {
		text-align: center;
		padding: 22px 0;
	}

	.upload-content svg {
		margin-top: 0 !important;
		color: rgba(34, 38, 56, 0.32)
	}

	.upload-content h3 {
		color: gray;
	}

	.section-title {
		color: #161e2e !important;
		line-height: 1.5rem !important;
		font-size: 1.225rem !important;
		font-weight: 700 !important;
		margin: 0 !important;
	}

	.section-description {
		color: #6b7280;
		margin-top: .25rem;
		line-height: 1.25rem;
		font-size: .985rem;
		font-weight: 500;
	}

	.line-label {
		color: #686c75 !important;
		font-weight: 600 !important;
		line-height: 1.25rem !important;
	}

	.line-info {
		color: #161e2e !important;
		line-height: 1.25rem !important;
		font-weight: 500;
	}

	.folder-status-container {
		border-radius: .375rem;
		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .05);
	}

	.folder-status-content {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0.7em 1rem;
		line-height: 1.25rem;
		max-width: 22em;
		font-weight: 500;
		border-width: 1px;
		border-color: transparent;
		border-radius: .25rem;
		background-color: #344457;
		color: #ffffff;
		text-transform: uppercase;

	}

	.upload-button, .upload-button:hover {
		background-color: #164892;
		color: white;
	}

	.ui.top.attached.header.padded {
		padding: 1.25rem 1rem !important;
	}
</style>